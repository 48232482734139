import {
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  Textarea,
  Select,
  SelectProps,
  CheckboxGroup,
  Checkbox,
  Radio,
  RadioGroup,
  Flex,
  ChakraProps,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputFieldProps,
} from '@chakra-ui/react';
import { CaretDown } from '@phosphor-icons/react';
import { FC, Fragment } from 'react';
import { useField } from 'react-final-form';
import { useTranslation } from '../../lib/hooks.context';
import { FormField as FormFieldProps, FormFieldOption } from '../../types';

const MhySelect = ({ value, ...props }: SelectProps) => (
  <Select
    iconColor="highlightColor"
    icon={<CaretDown size="24" />}
    iconSize="24px"
    {...(value && value !== '' ? { 'data-filled': true } : null)}
    value={value}
    {...props}
  />
);

export { MhySelect as Select };

const MhyNumber = ({
  size,
  isInvalid,
  ...props
}: Pick<NumberInputProps, 'size' | 'isInvalid'> &
  Omit<NumberInputFieldProps, 'size'>) => (
  <NumberInput size={size} isInvalid={isInvalid}>
    <NumberInputField {...props} />
  </NumberInput>
);

const getComponent = (type: FormFieldProps['type']) => {
  switch (type) {
    case 'text':
      return Input;
    case 'number':
    case 'phone':
      return MhyNumber;
    case 'textarea':
      return Textarea;
    case 'email':
      return Input;
    case 'select':
      return MhySelect;
    default:
      return Input;
  }
};

interface GroupFieldType {
  type: 'radio' | 'checkbox';
}

const groupFieldTypes = ['radio', 'checkbox'];

const GroupField = ({
  type,
  name,
  value,
  label,
  ...rest
}: FormFieldOption &
  GroupFieldType &
  Pick<FormFieldProps, 'name'> &
  ChakraProps) => {
  const {
    input: { checked, ...input },
    meta: { error, touched },
  } = useField(name, {
    type,
    value,
  });

  const GroupFieldComponent = type === 'checkbox' ? Checkbox : Radio;

  return (
    <GroupFieldComponent
      {...rest}
      {...input}
      isChecked={checked}
      isInvalid={error && touched}
    >
      {label}
    </GroupFieldComponent>
  );
};

// Group renders all the fields within the group and wraps it with appropriate wrapper
export const Group = ({
  options,
  ...rest
}: Pick<FormFieldProps, 'name'> &
  GroupFieldType & { options?: false | FormFieldOption[] } & ChakraProps) => {
  const {
    meta: { initial },
  } = useField(rest.name, {
    type: rest.type,
  });

  if (!Array.isArray(options)) {
    return null;
  }

  const GroupComponent = rest.type === 'radio' ? RadioGroup : CheckboxGroup;

  return (
    <GroupComponent defaultValue={initial}>
      <Flex flexWrap="wrap" gridGap="3">
        {options?.map((option) => (
          <GroupField key={option.value} {...rest} {...option} />
        ))}
      </Flex>
    </GroupComponent>
  );
};

const SingleCheckboxOrRadio = ({ type, name, option, isRequired }) => {
  const InputComponent = type === 'radio' ? Radio : Checkbox;
  const { input } = useField(name, {
    type,
  });
  return (
    <InputComponent
      {...input}
      isRequired={isRequired}
      type={type}
      name={name}
      value={option.value}
    >
      {option.label}
    </InputComponent>
  );
};

type InnerProps = FC<
  Omit<FormFieldProps, 'type'> & {
    type: Exclude<FormFieldProps['type'], 'fieldset'>;
    disabled?: boolean;
  }
>;

const FormField: InnerProps = ({
  name,
  type,
  label,
  required,
  info,
  options,
  children,
  disabled,
}) => {
  const {
    input,
    meta: { error, submitError, dirtySinceLastSubmit, touched },
  } = useField(name);

  const isInvalid =
    (submitError && !dirtySinceLastSubmit) || (error && touched);
  const t = useTranslation();

  // early exit radio and checkbox groups
  if (groupFieldTypes.includes(type)) {
    return (
      <FormControl
        id={name}
        isInvalid={isInvalid}
        isRequired={required}
        mb="6"
        as="fieldset"
      >
        {options ? (
          <FormLabel as="legend" fontSize="sm" fontWeight="normal">
            {label}
          </FormLabel>
        ) : null}
        {
          // eslint-disable-next-line no-nested-ternary
          options ? (
            options.length === 1 ? (
              <SingleCheckboxOrRadio
                type={type as 'checkbox' | 'radio'}
                name={name}
                option={options[0] as FormFieldOption}
                isRequired={required}
              />
            ) : (
              <Group
                type={type as 'checkbox' | 'radio'}
                name={name}
                options={options as FormFieldOption[]}
              />
            )
          ) : null
        }
        {children}
        {info ? <FormHelperText>{info}</FormHelperText> : null}
        {isInvalid ? (
          <FormErrorMessage>{error || submitError}</FormErrorMessage>
        ) : null}
      </FormControl>
    );
  }

  const InputComponent = getComponent(type);

  if (!InputComponent) {
    return null;
  }

  return (
    <FormControl
      id={name}
      isInvalid={isInvalid}
      isRequired={required}
      // eslint-disable-next-line no-nested-ternary
      mb={type === 'hidden' ? 0 : options ? 6 : 4}
      css={{ 'break-inside': 'avoid-column' }}
    >
      {type !== 'hidden' ? (
        <FormLabel htmlFor={name} fontSize="sm" fontWeight="normal">
          {label}
        </FormLabel>
      ) : null}
      {options ? (
        <InputComponent
          {...input}
          type={type}
          isInvalid={isInvalid}
          id={name}
          maxWidth="600px"
          bg="backgroundColor"
          disabled={disabled}
        >
          {(options as any[]).map(
            ({ label: optionLabel, options: optGroup, value }, i) => {
              if (!value && typeof optGroup !== 'undefined') {
                return (
                  <optgroup label={optionLabel} key={optionLabel}>
                    {optGroup.map(({ value: goptVal, label: goptLabel }) => (
                      <option key={goptVal} value={goptVal}>
                        {goptLabel}
                      </option>
                    ))}
                  </optgroup>
                );
              }

              if (!i) {
                return (
                  <Fragment key={`default-${value}`}>
                    <option key="none" value="" hidden>
                      {t('select_default')}
                    </option>
                    <option key={value} value={value}>
                      {optionLabel}
                    </option>
                  </Fragment>
                );
              }
              return (
                <option key={value} value={value}>
                  {optionLabel}
                </option>
              );
            },
          )}
        </InputComponent>
      ) : (
        <InputComponent
          {...input}
          type={type}
          isInvalid={isInvalid}
          id={name}
          maxWidth="600px"
          bg="backgroundColor"
          disabled={disabled}
          size="normal"
        />
      )}
      {children}
      {info ? <FormHelperText>{info}</FormHelperText> : null}
      {isInvalid ? (
        <FormErrorMessage>{error || submitError}</FormErrorMessage>
      ) : null}
    </FormControl>
  );
};

export default FormField;
